<template>
   <div class="alert-bar" v-if="this.$store.state.alertString">
{{ this.$store.state.alertString }}
   </div>


</template>

<script>


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.alert-bar {
  top:0;
  right:0;
  left:0;
  bottom:0;
  font-size:14px;
  font-weight: bold;
  height:60px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position:absolute;
  z-index:99;
  border-radius:0 0 10px 10px;
  background-color: var(--info);
  color:white;
}

</style>
