/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';


const store = new Vuex.Store({
    state() {
return {
    alertString:''
}
    }
});

export default store;